import Vue from 'vue'
import App from './App.vue'
// import router from './router'
// import store from './store/index'
import vueCustomElement from 'vue-custom-element'

Vue.use(vueCustomElement);
Vue.customElement('zoom-embedded', App);

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
