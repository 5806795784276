<template>
  <div class="zoom-meeting-embedded">
    <!-- <h1>Zoom Meeting SDK Sample Vue.js 2</h1> -->
    <!-- <pre>Signature: {{ signature }}</pre>
    <pre>clientid: {{ clientid }}</pre>
    <pre>Username: {{ username }}</pre>
    <pre>Useremail: {{ useremail }}</pre>
    <pre>Meetingid: {{ meetingid }}</pre>
    <pre>leaveurl: {{ leaveurl }}</pre> -->
    <button v-if="configured && !meetingStarted" @click="startMeeting">Join Meeting</button>
    <div id="zoomcontainerdrop"></div>
    <!-- <ThanksParticipating v-if = "step == 1"/>-->

  </div>
</template>

<script>
// import axios from "axios";
import { ZoomMtg } from '@zoomus/websdk';
// import ThanksParticipating from '@/components/ThanksParticipating.vue';

export default {
  name: 'EmbeddedZoom',
  components: {
    // ThanksParticipating,
  },
  props: [
    'signature',
    'username',
    'useremail',
    'meetingid',
    'clientid',
    'leaveurl',
    'pwd',
  ],
  computed: {
    configured(){
      if(this.signature == null) {
        return false;
      }
      if(this.username == null) {
        return false;
      }
      if(this.useremail == null) {
        return false;
      }
      if(this.meetingid == null) {
        return false;
      }
      if(this.clientid == null) {
        return false;
      }
      return true;
    },
  },
  created () {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.14.0/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('en-US');
    ZoomMtg.i18n.reload('en-US');
  },
  mounted() {
    const { thankyou } = this.getQueryParams();
    this.step = (thankyou) ? 1 : 0;

    // this.generateSignature(key, secret, meetingNumber, role);
    // eslint-disable-next-line no-debugger
    if(this.step !== 1){
      const params = this.getQueryParams();
      this.signature= params.signature; 
      this.username= params.username; 
      this.useremail= params.useremail; 
      this.meetingid= params.meetingid; 
      this.clientid= params.clientid; 
      this.leaveurl= params.leaveurl; 
      this.passWord= params.pwd; 
      ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
        console.log("inMeetingServiceListener onUserJoin", data);
        this.emitCustomEvent('onUserJoin', data);
      });
      // this.fixContainer();
      this.startMeeting();
    }else{
      document.getElementById("zmmtg-root").style.display = "none";
      (window.parent || window).postMessage('HIDESECTION','*');
      
    }
  },
  watch: {
    created(newValue) {
      if(newValue) {
        this.fixContainer();
      }
    },
  },
  data() {
    return {
      clientId: 'PkEKTDgTe6O6Z_rf2KfNw',
      clientSecret: 'iyCtYf3q2me1fRU3EaQmZuLCJG2K2PoL',
      meetingNumber: '84448800551',

      authEndpoint: "",
      passWord: "",
      role: 0,
      registrantToken: '',
      zakToken: '',
      meetingStarted: false, 
      // leaveUrl: "http://localhost:8080",
      step:0,
    }
  },
  methods: {
    getQueryParams() {
      const url = window.location.href;
      // eslint-disable-next-line no-debugger
      debugger;
      const paramArr = url.slice(url.indexOf('?') + 1).split('&');
      const params = {};
      // eslint-disable-next-line array-callback-return
      paramArr.map((param) => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
      });
      return params;
    },
    emitCustomEvent(eventName, eventData) {
      const event = new CustomEvent(eventName, { detail: eventData });
      window.dispatchEvent(event);
    },

    fixContainer() {
      // document.getElementById('zoomcontainerdrop').appendChild(document.getElementById('zmmtg-root'));
    },
    startMeeting() {
      this.emitCustomEvent('onMeetingStart', null);
      this.meetingStarted = true;
      
      document.getElementById("zmmtg-root").style.display = "block";


      ZoomMtg.init({
        leaveUrl: this.leaveurl,
        success: (success) => {
          console.log(success);
          ZoomMtg.join({
            signature: this.signature,
            sdkKey: this.clientid,
            meetingNumber: this.meetingid,
            passWord: this.passWord,
            userName: this.username,
            userEmail: this.useremail,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            }
          });
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* 
body {
  overflow: auto;
}

#zoomcontainerdrop {
  position: relative;
}

.meeting-client {
  position: static;
}
.meeting-client-inner {
  position: static;
}
.meeting-app {
  max-width: 100%;
}

.join-dialog {
  max-width: 100%;
}

#zmmtg-root {
    position: static;
  } */

.zoom-meeting-embedded button {
    margin-top: 20px;
    background-color: #2D8CFF;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    
  }

  .zoom-meeting-embedded button:hover {
    background-color: #2681F2;
  }


  #content_container {
    max-width: 100%;
  }



</style>
