<template>
  <div id="app">
    <HelloWorld
      :signature="signature"
      :username="username"
      :useremail="useremail"
      :meetingid="meetingid"
      :leaveurl="leaveurl"
      :clientid="clientid"
    />
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  props: [
    'signature',
    'username',
    'useremail',
    'meetingid',
    'leaveurl',
    'clientid',
  ],
  created() {
    // this.updateIP();
    this.registerFonts();
  },
  mounted() {
  
  },
  methods: {
    registerFonts() {
      const css = `
      @font-face {
        font-family: 'Absolut Sans 2021';
        src: url("https://plant.hellosandia.com/fonts/AbsolutSans2021-Regular.otf") format("opentype");
      }

      @font-face {
        font-family: 'Absolut Handwritten Web';
        src: url('https://plant.hellosandia.com/fonts/AbsolutHandwrittenHeadline2021-Regular.otf') format('opentype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      `;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.id = 'fontdef';
      style.type = 'text/css';
      head.appendChild(style);
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
  }
}
</script>

<style>
/* #app {
  width: 70%;
  margin: auto;
  text-align: center;
} */
/* #zmmtg-root {
  display: none;
} */
</style>
